import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  RowData,
  Table,
} from '@tanstack/react-table';
import SkapaTableStyles from '@ingka/table';
import styled from 'styled-components';
import { TableContent } from 'components/common/TableContent';
import { TablePaginationRow } from 'components/common/TablePaginationRow';

/* eslint-disable react/jsx-key */
// as the "key" key is already contained in the react table props

const SkapaTableWithAlignLine = styled(SkapaTableStyles)`
  td {
    vertical-align: middle;
  }
`;

export const DataTable = <T extends RowData>({
  columns,
  data,
}: {
  columns: Array<ColumnDef<T>>;
  data: Array<T>;
}) => {
  const table: Table<T> = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <SkapaTableWithAlignLine>
        <TableContent tableData={table} />
      </SkapaTableWithAlignLine>
      <TablePaginationRow tableData={table} />
    </div>
  );
};
