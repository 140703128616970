import { Configuration, PopupRequest } from '@azure/msal-browser';
import { appConfig } from 'appConfig';

export const msalConfiguration: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${appConfig.appTenantId}`,
    clientId: appConfig.appClientId,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest: PopupRequest = {
  scopes: appConfig.appScope.split(','),
};
