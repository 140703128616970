import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import AppHeader from 'components/AppHeader';
import { AboutPage } from 'pages/About';
import AppFooter from 'components/AppFooter';
import CustomersPage from 'pages/Customers';
import OrdersPage from 'pages/Orders';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { CameraPage } from 'pages/Camera';
import { ToastAnchor } from 'components/ToastAnchor';
const queryClient = new QueryClient();

const App = (props: { pca: IPublicClientApplication }) => (
  <MsalProvider instance={props.pca}>
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <AppHeader />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/customers">
              <CustomersPage />
            </Route>
            <Route path="/orders">
              <OrdersPage />
            </Route>
            <Route path="/camera">
              <CameraPage />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <AppFooter />
          <ToastAnchor />
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  </MsalProvider>
);

export default App;
