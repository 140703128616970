import { configuredAxios } from 'api/configuredAxios';
import { appConfig } from 'appConfig';
import { CustomerDto } from 'api/genTypes/customer';

export const customersAPI = {
  fetchAll: () =>
    configuredAxios
      .get<Array<CustomerDto>>(appConfig.customerEndpoit)
      .then((result) => result.data),
  create: (c: Omit<CustomerDto, 'id'>) => configuredAxios.post(appConfig.customerEndpoit, c),
  delete: (c: CustomerDto) => configuredAxios.delete(appConfig.customerEndpoit + `/${c.id}`),
  update: (c: CustomerDto) => configuredAxios.put(appConfig.customerEndpoit + `/${c.id}`, c),
};
