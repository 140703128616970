import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Page } from 'components/layout';
import CustomersPage from 'pages/Customers';

const Home = () => (
  <Page>
    <AuthenticatedTemplate>
      <CustomersPage />
    </AuthenticatedTemplate>
  </Page>
);

export default Home;
