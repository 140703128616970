declare var window: any;

export const appConfig = {
  backendBaseUrl: window?.env?.BACKEND_BASEURL,
  customerEndpoit: window?.env?.CUSTOMER_BACKEND_ENDPOINT,
  orderEndpoint: window?.env?.ORDER_BACKEND_ENDPOINT,
  appClientId: window?.env?.CLIENT_ID,
  appTenantId: window?.env?.TENANT_ID,
  appScope: window?.env?.SCOPE,
  appInsightKey: window?.env?.INSIGHTS_KEY,
  isPWA: false,
  version: 'v0.22',
};

if (!Object.values(appConfig).every((x) => x !== null && x !== '' && x !== undefined)) {
  throw Error(`Missing value for env variables in env-config`);
}
