import Button from '@ingka/button';
import Text from '@ingka/text';
import { appConfig } from 'appConfig';
import { Stack, Page } from 'components/layout';
import React from 'react';
import styled from 'styled-components';
import ikeaSkapaToken from '@ingka/variables';
import { checkForNewSWVersion, updateSW } from 'pages/About/swUpdateHelper';
import { useAsyncCall } from 'utils/hooks';
import { useQuery } from 'react-query';

const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 48px;
  border-radius: 2px;
  background-color: ${ikeaSkapaToken.colourNeutralGrey100};
`;

const CardRow = styled.div`
  margin: 12px 0;
`;

const SWUpdatePanel = () => {
  const hasNewSWVersion = useQuery('checkForNewSWVersion', checkForNewSWVersion, {
    refetchOnMount: true,
  });

  const [isUpdating, updateSWNow] = useAsyncCall(updateSW);

  return (
    <Card>
      <CardRow>
        <Text headingSize="m">Current version: {appConfig.version}</Text>
      </CardRow>
      {hasNewSWVersion.isFetching ? (
        <CardRow>
          <Text>Checking for SW updates ...</Text>
        </CardRow>
      ) : hasNewSWVersion.data ? (
        <>
          <CardRow>
            <Text>New app version available!</Text>
          </CardRow>
          <CardRow>
            <Button type="primary" small onClick={updateSWNow} loading={isUpdating}>
              Click to update
            </Button>
          </CardRow>
        </>
      ) : (
        <>
          <CardRow>
            <Text>No SW update available.</Text>
          </CardRow>
        </>
      )}
    </Card>
  );
};

const VSpace = styled.div`
  height: 64px;
`;

export const AboutPage = () => (
  <Page>
    <Stack>
      <Text bodySize="l">Hej, welcome to SWD Reference Frontend SPA!</Text>
      <VSpace />
      {appConfig.isPWA && <SWUpdatePanel />}
    </Stack>
  </Page>
);
