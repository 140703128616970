import React, { useEffect, useState } from 'react';
import { Table, RowData } from '@tanstack/react-table';
import styled from 'styled-components';

const PageCount = styled.div`
  display: flex;
  align-items: center;
`;

const clamp = (p: { min: number; max: number; value: number }): number =>
  Math.min(p.max, Math.max(p.min, p.value));

export const TablePaginationRow = <T extends RowData>({ tableData }: { tableData: Table<T> }) => {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    tableData.setPageIndex(pageNumber - 1);
  }, [pageNumber, tableData]);

  return (
    <div style={{ display: 'flex', gap: 2, marginTop: 8 }}>
      <button onClick={() => tableData.setPageIndex(0)} disabled={!tableData.getCanPreviousPage()}>
        {'<<'}
      </button>
      <button onClick={() => tableData.previousPage()} disabled={!tableData.getCanPreviousPage()}>
        {'<'}
      </button>
      <button onClick={() => tableData.nextPage()} disabled={!tableData.getCanNextPage()}>
        {'>'}
      </button>
      <button
        onClick={() => tableData.setPageIndex(tableData.getPageCount() - 1)}
        disabled={!tableData.getCanNextPage()}
      >
        {'>>'}
      </button>
      <PageCount>
        <div>Page</div>
        <strong>
          {tableData.getState().pagination.pageIndex + 1} of {tableData.getPageCount()}
        </strong>
      </PageCount>
      <span>
        | Go to page:
        <input
          aria-label="pageCount"
          style={{ width: '50px' }}
          type="string"
          //value={pageNumber}
          onChange={(e) => {
            setPageNumber(
              clamp({
                min: 1,
                max: tableData.getPageCount(),
                value: Math.max(1, Number(e.target.value)),
              })
            );
          }}
        />
      </span>
      <select
        value={tableData.getState().pagination.pageSize}
        onChange={(e) => {
          tableData.setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};
