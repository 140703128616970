import { configuredAxios } from 'api/configuredAxios';
import { appConfig } from 'appConfig';
import { OrderDto } from 'api/genTypes/order';

export const ordersAPI = {
  fetchAll: () =>
    configuredAxios.get<Array<OrderDto>>(appConfig.orderEndpoint).then((result) => result.data),
  get: (orderId: string) =>
    configuredAxios
      .get<OrderDto>(appConfig.orderEndpoint + `/${orderId}`)
      .then((result) => result.data),
};
