import { Page } from 'components/layout';
import Message from 'components/Message';
import React from 'react';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation();

  return (
    <Page>
      <Message>404: Page not found - {location.pathname}</Message>
    </Page>
  );
};

export default NotFound;
