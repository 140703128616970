import { Page } from 'components/layout';
import React from 'react';
import { DataTable } from 'components/common/DataTable';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { ordersAPI } from 'api/ordersAPI';
import { OrderDto } from 'api/genTypes/order';

const PageTitle = styled.h2`
  text-align: center;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 48px;
`;

const ordersColumnsConfig = [
  {
    header: 'Orders',
    columns: [
      {
        header: 'Order #',
        accessorKey: 'orderNumber',
      },
      {
        header: 'Customer',
        accessorKey: 'customerName',
      },
      {
        header: 'Order date',
        accessorFn: (c: OrderDto) =>
          new Date(c.orderDate).toISOString().slice(0, 'YYYY-MM-DD'.length),
      },
      {
        header: 'Order status',
        accessorKey: 'orderStatus',
      },
    ],
  },
];

const OrdersPage = () => {
  const orders = useQuery('ORDERS_QUERY', () => ordersAPI.fetchAll(), {
    refetchOnWindowFocus: false,
  });

  const content = orders.isError ? (
    <div>Error</div>
  ) : orders.isLoading ? (
    <div>Fetching ...</div>
  ) : (
    <DataTable columns={ordersColumnsConfig} data={orders.data} />
  );

  return (
    <Page>
      <PageTitle>Orders</PageTitle>
      <CenterContainer>{content}</CenterContainer>
    </Page>
  );
};
export default OrdersPage;
