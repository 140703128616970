import Button from '@ingka/button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '@ingka/text';

const FileImage = styled.img`
  margin: 12px 0px;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const HStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageCard = (props: { file: File; onDelete: () => void }) => {
  const [src, setSrc] = useState(null);
  useEffect(() => {
    setSrc(URL.createObjectURL(props.file));
  }, [props.file]);

  return (
    <Stack>
      <FileImage src={src} alt="captured image" />
      <HStack>
        <Text>Size: {Math.floor(props.file.size / 1024)}kb</Text>
        <Text>Date: {new Date(props.file.lastModified).toISOString().slice(0, 19)}</Text>
        <Button small type="secondary" onClick={props.onDelete}>
          Delete
        </Button>
      </HStack>
    </Stack>
  );
};
