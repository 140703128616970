import Button from '@ingka/button';
import Text from '@ingka/text';
import { Page } from 'components/layout';
import React from 'react';
import { ImageCard } from 'pages/Camera/ImageCard';
import styled from 'styled-components';
import { useLocalForgeFiles } from 'utils/hooks';

const createImageFileInput = () => {
  const inputE = document.createElement('input');
  inputE.setAttribute('type', 'file');
  inputE.setAttribute('accept', 'image/*');
  inputE.setAttribute('capture', '');
  inputE.setAttribute('data-testid', 'IMAGE_FILE_INPUT_ID');
  return inputE;
};

const HDivider = styled.div`
  border: 1px solid RGBA(128, 128, 128, 0.5);
  height: 2px;
  margin: 32px 4px;
`;

const HStack = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CameraPage = () => {
  const { files, storeFile: addFile, removeFile } = useLocalForgeFiles();

  const onSelectFile = () => {
    const inputE = createImageFileInput();
    inputE.style.display = 'none';
    document.body.appendChild(inputE);
    inputE.addEventListener('change', (evt: Event) => {
      const file: File | undefined = (evt.target as HTMLInputElement)?.files[0];
      if (file) {
        const newFile = new File([file], 'img' + new Date().toISOString() + '.jpg', {
          type: file.type,
          lastModified: file.lastModified,
        });
        addFile(newFile);
      }
      document.body.removeChild(inputE);
    });
    inputE.click();
  };

  return (
    <Page>
      <Button id="SELECT_BUTTON" onClick={onSelectFile}>
        Capture
      </Button>
      <HDivider />
      <HStack>
        <Text>Cached files</Text>
        <Text>Total: {Math.floor(files.reduce((acc, f) => acc + f.size, 0) / 1024)} kB</Text>
      </HStack>
      {files.map((f) => (
        <ImageCard file={f} key={f.lastModified} onDelete={() => removeFile(f)} />
      ))}
    </Page>
  );
};
