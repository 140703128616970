/* istanbul ignore file */
export const delay = <V>(ms: number, v: V): Promise<V> =>
  new Promise((r) => setTimeout(() => r(v), ms));

const ServiceWorkerUpdateTimeoutMS = 5000;

export const updateSW = async () =>
  'serviceWorker' in navigator
    ? navigator.serviceWorker.ready
        .then((swr) => swr.waiting?.postMessage({ type: 'SKIP_WAITING' }))
        .then(() => delay(ServiceWorkerUpdateTimeoutMS, 'notCare'))
        .then(() => window.location.reload())
    : undefined;

export const checkForNewSWVersion = async (): Promise<boolean> =>
  'serviceWorker' in navigator
    ? Promise.race([
        navigator.serviceWorker.ready
          .then((swr) => {
            swr.update();
            return delay(ServiceWorkerUpdateTimeoutMS, swr);
          })
          .then((swr) => Boolean(swr.waiting)),
        delay(ServiceWorkerUpdateTimeoutMS + 1000, false),
      ])
    : false;
