import React from 'react';
import styled from 'styled-components';
import { contentDelimiterStyles, appFooterHeight } from 'components/layout';
import ikeaSkapaToken from '@ingka/variables';

const FooterContainer = styled.footer`
  background-color: ${ikeaSkapaToken.colourNeutralGrey900};
  font-size: 16px;
  color: white;
  text-align: center;
`;

const FooterContent = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${contentDelimiterStyles};
  padding: 64px 16px;
  height: ${appFooterHeight};
`;

const CopyRight = styled.p`
  font-size: 14px;
`;
const AppFooter = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <CopyRight>© Inter IKEA Systems B.V. 1999 - {new Date().getFullYear()}</CopyRight>
      </FooterContent>
    </FooterContainer>
  );
};

export default AppFooter;
